import { useBusinessStore } from "~/store/business.store";

import { safeRoutes } from "~/utils/routes";

export function useBusinessRoutes(businessSlug?: string) {
  const selectedBusiness = useBusinessStore((state) => state.business);
  const slug = businessSlug ?? selectedBusiness.slug;

  const businessRoutes = {
    creditReports: {
      home: safeRoutes.business.creditReports.home(slug),
      find: safeRoutes.business.creditReports.find(slug),
      equifaxDetails: safeRoutes.business.creditReports.equifaxDetails(slug),
      details: safeRoutes.business.creditReports.details(slug),
      equifax: safeRoutes.business.creditReports.equifax(slug),
      paymentHistory: (loanId?: string) =>
        safeRoutes.business.creditReports.paymentHistory(slug, loanId),
    },
    business: {
      funding: safeRoutes.business.funding(slug),
      home: safeRoutes.business.home(slug),
    },
    lending: {
      home: safeRoutes.business.lending.home(slug),
      lendflow: safeRoutes.business.lending.lendflow(slug),
      lendica: safeRoutes.business.lending.lendica(slug),
      prime: safeRoutes.business.lending.prime(slug),
    },
    programs: {
      businessPlan: safeRoutes.business.programs.businessPlan(slug),
    },
  };

  return { businessRoutes };
}
